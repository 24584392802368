<template>
  <div>
    <HeaderComp />
    <c-box
      padding-top="30px"
      padding-bottom="80px"
    >
      <c-box
        w="100%"
        max-width="1200px"
        mx="auto"
      >
        <c-breadcrumb
          v-chakra="{
            ol: {
              li: {
                a: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
                span: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },
          }"
          margin-top="20px"
          margin-bottom="20px"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="primary.400"
              font-size="14px"
              font-fammily="Roboto"
            >
              Beranda
            </c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item is-current-page>
            <c-breadcrumb-link
              href="#"
              font-size="14px !important"
              font-fammily="Roboto !important"
            >
              Privacy Policy
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>
        <c-heading
          v-if="item"
          as="h2"
          font-size="36px"
          font-weight="600"
          color="black.900"
          margin-bottom="40px"
        >
          {{ item.title }}
        </c-heading>
        <c-box
          v-if="item"
          border-top="1px solid #F2F2F2"
          padding-top="30px"
        >
          <c-text
            font-size="16px"
            line-height="28px"
            color="darkGrey.900"
            margin-bottom="30px"
            v-html="item.content"
          />
          <c-accordion
            v-chakra="{
              '.css-0': {
                ':first-child': {
                  borderTop: 'none',
                },
                ':last-child': {
                  borderBottom: 'none',
                },
                button: {
                  ':focus': {
                    outline: 'none',
                    boxShadow: 'none',
                  },
                  svg: {
                    path: {
                      fill: '#008C81',
                    },
                  },
                },
              },
            }"
            :allow-multiple="true"
            :default-index="[0]"
            box-shadow="4px 4px 50px 5px #0000000D"
            border-radius="6px"
          >
            <c-accordion-item
              v-for="(child, idx) in item.privacyPolicy"
              :key="idx"
            >
              <c-accordion-header>
                <c-box
                  flex="1"
                  text-align="left"
                  font-weight="700"
                  py="10px"
                  px="18px"
                  font-family="Roboto"
                  font-size="18px"
                  color="black"
                  v-html="child.subTitle"
                />
                <c-accordion-icon />
              </c-accordion-header>
              <c-accordion-panel
                px="36px"
                pb="4"
                border-top="1px solid #F2F2F2"
              >
                <c-text
                  v-chakra="{
                    ul: {
                      paddingLeft: '30px',
                      color: '#555555',
                    },
                  }"
                  font-family="Roboto"
                  font-size="16px"
                  color="darkGray"
                  margin-bottom="30px"
                  line-height="28px"
                  v-html="child.subContent"
                />
              </c-accordion-panel>
            </c-accordion-item>
          </c-accordion>
        </c-box>
      </c-box>
    </c-box>
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import FooterComp from '@/components/layouts/footer/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PrivacyPolicyPage',
  components: {
    HeaderComp,
    FooterComp,
  },
  data() {
    return {
      item: '',
    }
  },
  computed: {
    ...mapGetters({
      axiosCMS: 'customAxiosCMS',
    }),
  },
  async mounted() {
    this.loadPrivacyPolicy()
  },
  methods: {
    loadPrivacyPolicy() {
      this.axiosCMS.get('/privacy-and-policy').then((res) => {
        this.item = res.data
      })
    },
  },
}
</script>
